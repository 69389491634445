import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import path from 'path';
import { FaPlus } from 'react-icons/fa';
import { FiEdit2, FiLoader } from 'react-icons/fi';
import dates from '~/utils/dates';
import api from '~/services/api';
import Upload from '~/services/forms/upload';
import './styles.css';

// Components
import Template from '~/pages/admin/Template';
import { Button } from '~/components/Forms';
import Loading from '~/components/Default/Loading';
import HistoricBox from '~/components/Boxs/Historic';

// Form
import FormBill from './Form';

// Context
import { MixPanelContext, GeneralContext, MessagesContext, ModalContext } from '~/contexts';

const Historic: React.FC = () => {
    const { user, client }           = useContext(GeneralContext);
    const { buttonClick, error }     = useContext(MixPanelContext);
    const [ready, setReady]          = useState(false);
    const [hists, setHists]          = useState<any>(null);
    const [filesLoad, setFilesLoads] = useState<any>(null);

    const [messages, setMessages]    = useState<any>(<></>);
    const file                       = useRef<HTMLInputElement>(null);

    // Contexts
    const { setAlert, setUploadFile, uploadFile }       = useContext(MessagesContext);
    const { setModalOpen, setModalTitle, setModalBody } = useContext(ModalContext);
    const Modal                                         = useContext(ModalContext);

    function delBill(id: number) {
        setReady(false)

        api.delete(`/bills/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false)
            setMessages(<div className="msg">Fatura deletada com sucesso!!</div>)
            setTimeout(() => setMessages(<></>), 3000);
            setHists(null)
        })
    }

    function openModal (id: number) {

        Modal.setModalClass('modalProposalInative enz')
        Modal.setModalTitle('Excluindo Conta de Energia')
        Modal.setModalBody(<>
            <div>Deseja realmente Excluir essa fatura?</div>
            <div className="alert"><b>* Tenha cuidado, esse processo é irreversível!</b></div>

            <footer>
                <button id="btnSave" type="button" onClick={()=>{delBill(id)}} >Excluir</button>
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </footer>
        </>)

        // delBill(id)
        Modal.setModalOpen(true)
  }

    // function activeBill(id:number){
    //     setModalOpen(false)
    //     setReady(false)
    //     api.put(`/bills/active/${id}`, '',{
    //         headers: { Authorization: user.token }
    //     }).then(resp => {
    //         setHists(null)
    //     }).catch(err =>{
    //         setHists(null)
    //     })
    // }

    function openModaFile(data: any) {
        let errDate, errTariffModality, errBill = false;

        if (data?.errors.date === true) {
            errDate = data.errors?.date
        }

        if (data?.errors.tariff_modality === true) {
            errTariffModality = data.errors?.tariff_modality
        }

        if (data === null) {
            errBill = true
        }

        // Set title Modal
        setModalTitle('Erro ao processar a fatura')

        if (errTariffModality) {
            setModalBody(
                <div className="modal_file">
                    <p>Você está tentando enviar uma conta de energia com a <b>modalidade tarifária diferente</b> do seu cadastro</p>
                    <p>Envie uma outra conta da mesma modalidade tarifária ou crie um outro usuário</p>
                    <div className="button">
                        <Button
                            type="button"
                            className="edit"
                            func={() => setModalOpen(false)}
                            text="Ok"
                        />
                    </div>
                </div>
            )
        } else if (errDate) {
            let date = dates.getDateString(data.data.reference_date)

            setModalBody(
                <div className="modal_file">
                    <p>Você está tentando enviar uma conta de energia do <b>mês {date.month + '-' + date.year}</b>, porém, <b>já existe</b> uma conta no sistema para esse mês.</p>
                    <p>Envie uma outra conta ou exclua a que já existe no sistema para continuar</p>
                    <div className="button">
                        <Button
                            type="button"
                            className="edit"
                            func={() => setModalOpen(false)}
                            text="Ok"
                        />
                    </div>
                </div>
            )
        } else if (data?.bill.json.error) {
            let message = '';

            switch (data.bill.json.error) {
                case 511:
                    message = `<p>Você está tentando enviar uma conta muito antiga, você precisa enviar uma conta mais recente.</p>
                    <p>Envie uma outra conta com menos de <b>24 meses da data de refência</b>.</p>`;
                    break;

                default:
                    message = `<p>Ocorreu um erro ao processar automaticamente os dados da sua fatura. Você pode tentar enviar o arquivo novamente ou seguir o cadastro de outra forma.</p>`;
                    break;
            }

            setModalBody(
                <div className="modal_file">
                    <div dangerouslySetInnerHTML={{ __html: message }}></div>
                    <div className="button">
                        <Button
                            type="button"
                            className="edit"
                            func={() => helpAdmin(data.bill.id)}
                            text="Seguir cadastro de outra forma"
                        />
                        <Button
                            type="button"
                            className="ok"
                            func={() => setModalOpen(false)}
                            text="Fechar"
                        />
                    </div>
                </div>
            )
        } else if (errBill) {
            setModalBody(
                <div className="modal_file">
                    <p>Ocorreu um erro ao processar automaticamente os dados da sua fatura. Você pode tentar enviar o arquivo novamente ou seguir o cadastro de outra forma</p>

                    <div className="button">
                        <Button
                            type="button"
                            className="edit"
                            func={() => helpAdmin(data.bill.id)}
                            text="Seguir cadastro de outra forma"
                        />
                        <Button
                            type="button"
                            className="ok"
                            func={() => setModalOpen(false)}
                            text="Fechar"
                        />
                    </div>
                </div>
            )
        }
        setModalOpen(true)
    }

    function openModaInfoHelp() {
        setModalTitle('Fique atento ao seu e-mail de cadastro')
        setModalBody(
            <div className="modal_file">
                <div>
                    <p>Não foi possível avançar utilizando a leitura automática de sua fatura</p>
                    <p>Não se preocupe, nossa equipe irá cadastrar as informações da sua fatura em até 1 dia útil e te avisaremos por e-mail assim que estiver pronto</p>
                </div>
                <div className="button">
                    <Button
                        type="button"
                        className="edit"
                        func={() => setModalOpen(false)}
                        text="Confirmar"
                    />
                </div>
            </div>
        )
    }

    function helpAdmin(id: Number) {
        openModaInfoHelp()
        api.put(`/bills/help/${id}`, '', {
            headers: { Authorization: user.token }
        }).then(resp => {
            // console.log(resp.data)
        })
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            setUploadFile(true)
            let err = _validatorExtention(event?.currentTarget.files);
            let item: any = {};

            if (!err) {
                item['id']            = event.currentTarget.files[0].lastModified;
                item['status']        = 'Em andamento';
                item['file_name']     = event.currentTarget.files[0].name;
                item['file_complete'] = event.currentTarget.files;

                setFilesLoads({ ...filesLoad, ...item });

                if (user.role === 'admin') user.company = { id: Number(client), name: '', cnpj: '', signed: false, plan: null };

                Upload.sendFile(user, event.currentTarget.files[0])
                    .then(resp => {
                        // console.log("🚀 ~ onChange ~ resp:", resp)

                        if (resp) {
                            if (resp.errors === null) setHists(null)
                            else openModaFile(resp)
                        } else {
                            openModaFile(null)
                            error(user.id, 'Histórico', 'Um ou mais arquivos não foram enviados')
                        }

                        setTimeout(() => {
                            setUploadFile(false)
                            // setHists(null)
                            // setReady(false)
                        }, 4000)
                    })
            } else {
                setAlert('Por favor enviar arquivos no formato PDF', 'error')
                setTimeout(() => setUploadFile(false), 4000)
            }
        }
    }

    function _validatorExtention(files: FileList) {
        let _validFileExtensions = [".pdf"];
        let err = false;
        Array.from(files).forEach(file => {
            let ext = path.extname(file.name).toLocaleLowerCase();
            if (!_validFileExtensions.includes(ext)) err = true;
        })
        return err;
    }

    function openModalBill() {
        Modal.setModalOpen(false);
        Modal.setModalClass('enz mbills');
        Modal.setModalTitle('Cadastro Manual de Fatura')
        Modal.setModalBody(<FormBill setReady={setReady} setMessages={setMessages} />)
        Modal.setModalOpen(true)
    }
    // Get Data
    useEffect(() => {
        if (ready === false || hists === null) {
            setReady(false)

            api.get(`/bills/l/${user.role === 'admin' ? client : user.id}`, {
                headers: { Authorization: user.token }
            }).then(resp => {
                setTimeout(() => {
                    setHists(resp.data)
                    setReady(true)
                }, 1000)
            })
        }
    }, [ready, hists, user, filesLoad])

    // Update Button
    useEffect(() => { }, [uploadFile])

    return <Template page="Histórico de Contas" pageTitle="Histórico" className="hist">

        <div className="messageInfo">
            {messages}
        </div>
        {
            ready ? (<>
                <div className='header'>
                    <h1>Envios Recentes</h1>
                    <input id='file' name='file' type='file' onChange={onChange} ref={file} />
                    <div>
                        <h3>Envie suas 12 últimas contas de luz</h3>
                        <Button
                            type="button"
                            func={() => {
                                if (file && file.current) file.current.value = '';
                                file && file.current?.click();
                                buttonClick(user.id, 'Histórico', 'Enviar Conta');
                            }}
                            text={uploadFile ? 'Enviando...' : 'Enviar conta'}
                            icon={uploadFile ? <FiLoader size={12} /> : <FaPlus size={10} />}
                            className={`box ${uploadFile ? 'load' : 'edit'}`}
                            disabled={uploadFile ? true : false}
                        />

                        {
                            user.role === "admin" && <Button
                                type="button"
                                func={() => { openModalBill() }}
                                text='Cadastrar Conta'
                                icon={<FiEdit2 size={10} />}
                                className='box save'
                                disabled={uploadFile ? true : false}
                            />
                        }
                    </div>
                </div>

                {hists && hists.length > 0 ?
                    hists.map((el: any) => <HistoricBox
                        key={`box_${el.id}`}
                        id={el.id}
                        admin={user.role === "admin" ? true : false}
                        provider={el.provider}
                        modality={el.tariff_modality}
                        date={el.reference_date}
                        path={el.path}
                        signed={el.signed}
                        byApi={el.created_by_api ? true : false}
                        delBill={!el.signed && hists.length > 1 ? openModal : null}
                        setMessages={setMessages}
                    />)
                    :
                    <div className='noData'>Não foi possível localizar históricos</div>
                }

            </>
            ) : <Loading />
        }
    </Template>;
}

export default Historic;